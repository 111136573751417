body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}


.video-wrapper {
  margin:-3rem 0;
  position: relative;
  height:56.25vw;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 50%;
  width:100vw;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 56.25vw;
}
